var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var LegalMenuLeft = require('front/components/LegalMenuLeft/LegalMenuLeft');

require('./LegalDocuments.less');

module.exports = Page.extend({
    template: require('./LegalDocuments.jinja'),

    el: '.LegalDocuments',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Документы'),

    initialize: function (options) {
        this.menuLeft = new LegalMenuLeft();

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});
