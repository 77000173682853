var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var LegalMenuLeft = require('front/components/LegalMenuLeft/LegalMenuLeft');
var LegalRetailForm = require('front/components/LegalForms/LegalFormRetail/LegalFormRetail');

require('./LegalRetail.less');

module.exports = Page.extend({
    template: require('./LegalRetail.jinja'),

    el: '.Legal',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Стать партнером в рознице/сетях'),

    initialize: function (options) {
        this.menuLeft = new LegalMenuLeft();
        this.form = new LegalRetailForm();

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});
