var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/LegalMenuLeft/LegalMenuLeft.jinja"] = require( "front/components/LegalMenuLeft/LegalMenuLeft.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/LegalDocuments/LegalDocuments.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += "\n";
env.getTemplate("front/components/LegalMenuLeft/LegalMenuLeft.jinja", false, "assets/app/front/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "LegalMenuLeft")) {
var t_14 = t_11.LegalMenuLeft;
} else {
cb(new Error("cannot import 'LegalMenuLeft'")); return;
}
context.setVariable("LegalMenuLeft", t_14);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
output += t_15;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
output += t_17;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 5;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 5, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 5, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 6, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 6, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 9, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"LegalDocuments\">\n        <div class=\"LegalDocuments-floatNav\"></div>\n        <div class=\"LegalDocuments-background\">\n            <img class=\"LegalDocuments-backgroundImage\" src=\"\" srcset=\"\" alt=\"\">\n        </div>\n        <div class=\"LegalDocuments-left\">\n            ";
output += runtime.suppressValue((lineno = 19, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalMenuLeft"), "LegalMenuLeft", context, [runtime.makeKeywordArgs({"classes": "LegalMenuLeft--legal","subpage": "documents","caller": (function (){var macro_t_23 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_24 = "";;
frame = frame.pop();
return new runtime.SafeString(t_24);
});
return macro_t_23;})()})])), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"LegalDocuments-right\">\n            <div class=\"LegalDocuments-header\">\n                <div class=\"LegalDocuments-subtitle\">\n                    <span class=\"LegalDocuments-subtitleStroke\"></span>\n                    <div class=\"LegalDocuments-subtitleText\">\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive") {
output += "\n                            ";
output += runtime.suppressValue((lineno = 27, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Архив документов"])), env.opts.autoescape);
output += "\n                        ";
;
}
else {
output += "\n                            ";
output += runtime.suppressValue((lineno = 29, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
output += "\n                        ";
;
}
output += "\n                    </div>\n                </div>\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "legalPolitics") && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "legalPoliticsDocuments")) > 0) {
output += "\n                    <div class=\"LegalDocuments-description\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalPolitics")),"title"), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"LegalDocuments-text\">\n                        ";
frame = frame.push();
var t_27 = runtime.contextOrFrameLookup(context, frame, "legalPoliticsDocuments");
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("document", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_28),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                <table>\n                                    <tbody>\n                                    <tr>\n                                        <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_28),"caption"), env.opts.autoescape);
output += "</span></td>\n                                        <td>\n                                            <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 47, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                            <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 50, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                        </td>\n                                    </tr>\n                                    </tbody>\n                                </table>\n                            </a>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                ";
;
}
output += "\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "legalDocuments") && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "legalPartnersDocuments")) > 0) {
output += "\n                    <div class=\"LegalDocuments-description\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalDocuments")),"title"), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"LegalDocuments-text\">\n                        ";
frame = frame.push();
var t_31 = runtime.contextOrFrameLookup(context, frame, "legalPartnersDocuments");
if(t_31) {t_31 = runtime.fromIterator(t_31);
var t_30 = t_31.length;
for(var t_29=0; t_29 < t_31.length; t_29++) {
var t_32 = t_31[t_29];
frame.set("document", t_32);
frame.set("loop.index", t_29 + 1);
frame.set("loop.index0", t_29);
frame.set("loop.revindex", t_30 - t_29);
frame.set("loop.revindex0", t_30 - t_29 - 1);
frame.set("loop.first", t_29 === 0);
frame.set("loop.last", t_29 === t_30 - 1);
frame.set("loop.length", t_30);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_32),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                <table>\n                                    <tbody>\n                                    <tr>\n                                        <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_32),"caption"), env.opts.autoescape);
output += "</span></td>\n                                        <td>\n                                            <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 74, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                            <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 77, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                        </td>\n                                    </tr>\n                                    </tbody>\n                                </table>\n                            </a>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                ";
;
}
output += "\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive" && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "archiveDocuments")) > 0) {
output += "\n                    <div class=\"LegalDocuments-text\">\n                        ";
frame = frame.push();
var t_35 = runtime.contextOrFrameLookup(context, frame, "archiveDocuments");
if(t_35) {t_35 = runtime.fromIterator(t_35);
var t_34 = t_35.length;
for(var t_33=0; t_33 < t_35.length; t_33++) {
var t_36 = t_35[t_33];
frame.set("document", t_36);
frame.set("loop.index", t_33 + 1);
frame.set("loop.index0", t_33);
frame.set("loop.revindex", t_34 - t_33);
frame.set("loop.revindex0", t_34 - t_33 - 1);
frame.set("loop.first", t_33 === 0);
frame.set("loop.last", t_33 === t_34 - 1);
frame.set("loop.length", t_34);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_36),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                <table>\n                                    <tbody>\n                                    <tr>\n                                        <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_36),"caption"), env.opts.autoescape);
output += "</span></td>\n                                        <td>\n                                            <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 98, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                            <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                                ";
output += runtime.suppressValue((lineno = 101, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                            </svg>\n                                        </td>\n                                    </tr>\n                                    </tbody>\n                                </table>\n                            </a>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                ";
;
}
output += "\n\n                <div class=\"LegalDocuments-links\">\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive") {
output += "\n                    <a class=\"LegalDocuments-archiveLink\" href=\"";
output += runtime.suppressValue((lineno = 114, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-documents"])), env.opts.autoescape);
output += "\">\n                        <span>";
output += runtime.suppressValue((lineno = 115, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Актуальные документы"])), env.opts.autoescape);
output += "</span>\n                        ";
output += runtime.suppressValue((lineno = 116, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["archive-arrow"])), env.opts.autoescape);
output += "\n                    </a>\n                    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") == "actual" && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "archiveDocuments")) > 0) {
output += "\n                    <a class=\"LegalDocuments-archiveLink\" href=\"";
output += runtime.suppressValue((lineno = 119, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-documents-archive"])), env.opts.autoescape);
output += "\">\n                        <span>";
output += runtime.suppressValue((lineno = 120, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Архив документов"])), env.opts.autoescape);
output += "</span>\n                        ";
output += runtime.suppressValue((lineno = 121, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["archive-arrow"])), env.opts.autoescape);
output += "\n                    </a>\n                    ";
;
}
;
}
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/LegalDocuments/LegalDocuments.jinja"] , dependencies)