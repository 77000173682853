var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var LegalMenuLeft = require('front/components/LegalMenuLeft/LegalMenuLeft');
var LegalForm = require('front/components/LegalForms/LegalForm/LegalForm');

require('./Legal.less');

module.exports = Page.extend({
    template: require('./Legal.jinja'),

    el: '.Legal',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Стать поставщиком транспортных услуг'),

    initialize: function (options) {
        this.menuLeft = new LegalMenuLeft();
        this.form = new LegalForm();

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});
