var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/BrandsGrid/BrandsGrid.jinja"] = require( "front/components/BrandsGrid/BrandsGrid.jinja" );
dependencies["front/components/Locations/Locations.jinja"] = require( "front/components/Locations/Locations.jinja" );
dependencies["front/pages/Brand/BrandImage.jinja"] = require( "front/pages/Brand/BrandImage.jinja" );
dependencies["front/pages/Brand/BrandDescription.jinja"] = require( "front/pages/Brand/BrandDescription.jinja" );
dependencies["front/pages/Brand/BrandArrows.jinja"] = require( "front/pages/Brand/BrandArrows.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Brand/Brand.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TopNav")) {
var t_11 = t_8.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_11);
output += "\n";
env.getTemplate("front/components/BrandsGrid/BrandsGrid.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "BrandsGrid")) {
var t_15 = t_12.BrandsGrid;
} else {
cb(new Error("cannot import 'BrandsGrid'")); return;
}
context.setVariable("BrandsGrid", t_15);
output += "\n";
env.getTemplate("front/components/Locations/Locations.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "Locations")) {
var t_19 = t_16.Locations;
} else {
cb(new Error("cannot import 'Locations'")); return;
}
context.setVariable("Locations", t_19);
output += "\n\n";
env.getTemplate("front/pages/Brand/BrandImage.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "BrandImage")) {
var t_23 = t_20.BrandImage;
} else {
cb(new Error("cannot import 'BrandImage'")); return;
}
context.setVariable("BrandImage", t_23);
output += "\n";
env.getTemplate("front/pages/Brand/BrandDescription.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "BrandDescription")) {
var t_27 = t_24.BrandDescription;
} else {
cb(new Error("cannot import 'BrandDescription'")); return;
}
context.setVariable("BrandDescription", t_27);
output += "\n";
env.getTemplate("front/pages/Brand/BrandArrows.jinja", false, "assets/app/front/pages/Brand/Brand.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "BrandArrows")) {
var t_31 = t_28.BrandArrows;
} else {
cb(new Error("cannot import 'BrandArrows'")); return;
}
context.setVariable("BrandArrows", t_31);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("static"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_43,t_42) {
if(t_43) { cb(t_43); return; }
output += t_42;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_45,t_44) {
if(t_45) { cb(t_45); return; }
output += t_44;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_47,t_46) {
if(t_47) { cb(t_47); return; }
output += t_46;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("libs"))(env, context, frame, runtime, function(t_49,t_48) {
if(t_49) { cb(t_49); return; }
output += t_48;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 12, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"isNoindex")) {
output += "<meta name=\"robots\" content=\"noindex\">";
;
}
output += "\n        <meta property=\"og:type\" content=\"website\"/>\n        <meta property=\"og:image\" content=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"share"), env.opts.autoescape);
output += "\"/>\n        <meta property=\"og:image:width\" content=\"1200\"/>\n        <meta property=\"og:image:height\" content=\"630\"/>\n        <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 20, colno = 70, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n        <meta property=\"og:title\" content=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
output += "\"/>\n";
output += "\n    ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_static(env, context, frame, runtime, cb) {
var lineno = 25;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "static", b_static, frame, runtime, function(t_50,hole_30) {
if(t_50) { cb(t_50); return; }
hole_30 = runtime.markSafe(hole_30);
output += "\n    ";
output += runtime.suppressValue(hole_30, env.opts.autoescape);
output += "\n\n    <link href='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css' rel='stylesheet' />\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 31;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 32, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"classes": "TopNav--fixed TopNav--brand"})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 35;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "BrandPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 37;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Brand\">\n        <div class=\"Brand-content\">\n            <div class=\"Brand-media\">\n                <a class=\"Brand-back\" href=\"";
output += runtime.suppressValue((lineno = 41, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": [runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"category")),"type")]})])), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 41, colno = 120, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowNav"])), env.opts.autoescape);
output += runtime.suppressValue((lineno = 41, colno = 139, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Вернуться в каталог"])), env.opts.autoescape);
output += "</a>\n                <div class=\"Brand-img\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n                    ";
output += runtime.suppressValue((lineno = 43, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandImage"), "BrandImage", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
output += "\n                </div>\n                ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"category")),"type") != "beverages") {
output += "<div class=\"Brand-warning\">";
output += runtime.suppressValue((lineno = 46, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["warnRu"])), env.opts.autoescape);
output += "</div>\n                ";
;
}
output += "<div class=\"Brand-arrows\">\n                    ";
output += runtime.suppressValue((lineno = 50, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandArrows"), "BrandArrows", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
output += "\n                </div>\n            </div>\n\n            <div class=\"Brand-description\">\n                ";
output += runtime.suppressValue((lineno = 55, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandDescription"), "BrandDescription", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Brand-locations";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"locations")) {
output += " Brand-locations--active";
;
}
output += "\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            ";
output += runtime.suppressValue((lineno = 60, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Locations"), "Locations", context, [runtime.makeKeywordArgs({"classes": "Locations--brand","type": "brand","data": {"topLocations": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"topLocations"),"locations": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"locations")}})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"Brand-also\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"initial\" data-animation-delay=\"0\">\n            <h2 class=\"Brand-alsoHeader\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 67, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Вам также придутся по&nbsp;вкусу"]))), env.opts.autoescape);
output += "</h2>\n\n            <div class=\"Brand-alsoGrid\">";
output += runtime.suppressValue((lineno = 69, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandsGrid"), "BrandsGrid", context, [runtime.makeKeywordArgs({"classes": "BrandsGrid--brand","brands": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"similarBrands"),"favorites": runtime.contextOrFrameLookup(context, frame, "False")})])), env.opts.autoescape);
output += "</div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 74;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_51,hole_34) {
if(t_51) { cb(t_51); return; }
hole_34 = runtime.markSafe(hole_34);
output += "\n    ";
output += runtime.suppressValue(hole_34, env.opts.autoescape);
output += "\n\n    <script>\n        app.data.brand = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "brand"))), env.opts.autoescape);
output += ";\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_libs(env, context, frame, runtime, cb) {
var lineno = 82;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "libs", b_libs, frame, runtime, function(t_52,hole_35) {
if(t_52) { cb(t_52); return; }
hole_35 = runtime.markSafe(hole_35);
output += "\n    <script>\n        app.data.MAPBOX_API_KEY = '";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "MAPBOX_API_KEY"), env.opts.autoescape);
output += "';\n    </script>\n    <script src='https://api.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.js'></script>\n    ";
output += runtime.suppressValue(hole_35, env.opts.autoescape);
output += "\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_social: b_social,
b_static: b_static,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
b_data: b_data,
b_libs: b_libs,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Brand/Brand.jinja"] , dependencies)